<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      class="node-details-form"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input
          v-model="nodeToBind.node_name"
          :placeholder="__('pause node')"
        ></el-input>
      </el-form-item>

      <el-form-item
        class="form_label_top"
        :label="__('Pause duration')"
        prop="pause_node.data.pause_duration"
      >
        <el-slider
          v-model="nodeToBind.pause_node.data.pause_duration"
          show-input
          :max="10"
          :show-tooltip="false"
        ></el-slider>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "./BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions } from "vuex";

export default {
  mixins: [BaseNode],
  data() {
    return {};
  },
  computed: {
    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      return false;
    }
  },
  methods: {
    ...mapActions("tasks", {
      getLanguages: "getLanguages"
    }),

    initializePauseNode() {
      this.getLanguages();

      if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.pause_node)) {
        this.$set(this.nodeToBind, "pause_node", {});
        this.$set(this.nodeToBind.pause_node, "data", {});
        this.$set(this.nodeToBind.pause_node.data, "tts_voice_id", null);
        this.$set(this.nodeToBind, "node_type", NODE_TYPES.PAUSE.NODE_TYPE);
      }
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      return nodeToCleanUp;
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    this.initializePauseNode();
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";

.el-form-item:last-of-type {
  margin-bottom: 10px;
}

.text-input ::v-deep textarea {
  resize: none;
}
</style>
